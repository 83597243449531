<template>
  <v-container class="my-4">
    <v-row>
      <v-col>
        <v-card>
          <v-form ref="form" @submit.prevent="update()">
            <v-card-title>
              <div class="primary--text">Eğitimi Güncelle</div>
              <v-spacer />
              <v-btn small elevation="0" @click="$router.go(-1)"
                >GERİ DÖN</v-btn
              >
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="form.disabled"
                    label="Yayından kaldırılsın mı?"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    id="name"
                    v-model="form.name"
                    label="Eğitim Adı"
                    name="name"
                    type="text"
                    required
                    :rules="requiredRule"
                /></v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    id="urlCode"
                    v-model="form.urlCode"
                    label="URL Kodu"
                    hint="Türkçe, özel karakter ve boşluk kullanmayınız. Ör: sosyal-medya"
                    name="urlCode"
                    type="text"
                    required
                    :rules="urlCodeRules"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        id="price"
                        v-model="form.price"
                        label="Fiyat"
                        name="price"
                        type="text"
                        required
                        :rules="priceRules"
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        id="usagePeriod"
                        v-model="form.usagePeriod"
                        label="Kullanım Süresi (Gün)"
                        name="usagePeriod"
                        type="text"
                        required
                        :rules="usagePeriodRules"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        id="instructor"
                        v-model="form.instructor"
                        label="Eğitmen"
                        name="instructor"
                        type="text"
                        required
                        :rules="requiredRule"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-menu
                        ref="startDateMenu"
                        v-model="startDatePicker"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        left
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="form.startDate"
                            label="Başlangıç Tarihi"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            required
                            :rules="requiredRule"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.startDate"
                          @input="startDatePicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-menu
                        ref="finishDateMenu"
                        v-model="finishDatePicker"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        left
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="form.finishDate"
                            label="Bitiş Tarihi"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.finishDate"
                          @input="finishDatePicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-file-input
                        v-model="form.previewImg"
                        label="Önizleme Resmi"
                        accept=".png, .jpeg, .jpg, .gif, .svg"
                        truncate-length="50"
                        :rules="previewImgRules"
                        :loading="previewImgLoading"
                        @change="onPreviewImgChange"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <v-img :src="this.previewImgUrl" aspect-ratio="1" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <label class="v-label theme--light d-block mb-2"
                    >Açıklama</label
                  >
                  <ckeditor
                    :editor="editor"
                    :config="editorConfig"
                    v-model="form.desc"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                elevation="0"
                type="submit"
                :disabled="submitDisabled"
                class="mr-2 px-4"
                >KAYDET</v-btn
              >
              <v-btn
                :to="{
                  name: 'admin-courses',
                  params: { id: this.$route.params.id }
                }"
                elevation="0"
                >İPTAL ET</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import firebase from "@/plugins/firebase";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";

export default Vue.extend({
  metaInfo: {
    title: "Eğitimi Güncelle"
  },

  components: {
    ckeditor: CKEditor.component
  },

  data: () => ({
    form: {
      id: "",
      name: "",
      urlCode: "",
      desc: "",
      previewImg: null,
      startDate: null,
      finishDate: null,
      price: "0",
      disabled: false,
      usagePeriod: 30 // Days
    },
    startDatePicker: false,
    finishDatePicker: false,
    submitDisabled: false,
    previewImgUrl: "",
    previewImgLoading: false,
    previewImgDownloadUrl: "",
    editor: ClassicEditor,
    editorConfig: {
      language: "tr",
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "undo",
        "redo"
      ]
    }
  }),

  computed: {
    requiredRule() {
      return [v => !!v || "Zorunlu alan"];
    },

    urlCodeRules() {
      return [
        v => !!v || "Zorunlu alan",
        v => /^[a-z0-9-]*$/.test(v) || "Geçersiz URL kodu"
      ];
    },

    previewImgRules() {
      return [
        v => !v || v.size < 2097152 || "Dosya boyutu 2 MB'den küçük olmalıdır."
      ];
    },

    priceRules() {
      return [
        v => !!v || "Zorunlu alan",
        v =>
          !v ||
          /^((?=.*[1-9]|0)(?:\d{1,3}))((?=.*\d)(?:\.\d{3})?)*((?=.*\d)(?:,\d\d){1}?){0,1}$/gm.test(
            v
          ) ||
          "Geçersiz fiyat. Ör: 1.000 ya da 1.000,00"
      ];
    },

    usagePeriodRules() {
      return [
        v => !!v || "Zorunlu alan",
        v => /[0-9]/g.test(v) || "Sadece sayı giriniz"
      ];
    }
  },

  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.form.desc = "";
      this.resetValidation();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    onPreviewImgChange() {
      this.previewImgUrl = this.form.previewImg
        ? URL.createObjectURL(this.form.previewImg)
        : null;
    },

    async uploadPreviewImg() {
      try {
        const img = this.form.previewImg;
        const fileExt = img.name.substring(
          img.name.lastIndexOf("."),
          img.name.length
        );
        const fileName = this.form.urlCode + fileExt;

        const uploadTask = await firebase
          .storage()
          .ref()
          .child(`/course-images/${fileName}`)
          .put(img);

        this.previewImgLoading = false;

        this.previewImgDownloadUrl = await uploadTask.ref.getDownloadURL();
      } catch (err) {
        this.$notify({
          title: "Hata!",
          text: `Ön izleme resmi yüklenemedi.`,
          type: "error"
        });

        throw new Error(`Ön izleme resmi yüklenemedi. ${err.message}`);
      } finally {
        this.previewImgLoading = false;
      }
    },

    async update() {
      const form = this.$refs.form;

      if (!form.validate()) {
        return;
      }

      this.submitDisabled = true;

      this.$notify({
        text: "Eğitim güncelleniyor. Lütfen bekleyin..."
      });

      try {
        // Ön izleme resmini yükle
        if (this.form.previewImg) {
          await this.uploadPreviewImg();
        }

        const newCourse = {
          instructor: this.form.instructor,
          startDate: new Date(this.form.startDate),
          finishDate: this.form.finishDate
            ? new Date(this.form.finishDate)
            : null,
          desc: this.form.desc,
          disabled: this.form.disabled,
          name: this.form.name,
          urlCode: this.form.urlCode,
          img: this.previewImgDownloadUrl,
          price: this.convertToNumber(this.form.price),
          usagePeriod: Number(this.form.usagePeriod)
        };

        // Update course
        await firebase
          .firestore()
          .doc(`/apps/kahev-akademi/courses/${this.form.id}`)
          .update(newCourse);

        this.$notify({
          type: "success",
          title: "İşlem Tamam",
          text: "Eğitim başarıyla güncellendi."
        });
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: `Eğitim güncellenemedi.`
        });

        throw new Error(`Eğitim güncellenemedi. ${err.message}`);
      } finally {
        this.submitDisabled = false;
      }
    },

    async getCourse() {
      try {
        // Get course to database
        const qs = await firebase
          .firestore()
          .doc(`/apps/kahev-akademi/courses/${this.$route.params.id}`)
          .get();

        if (qs.exists) {
          this.form.id = qs.id;
          this.form.name = qs.data().name;
          this.form.urlCode = qs.data().urlCode;
          this.form.disabled = qs.data().disabled;
          this.form.instructor = qs.data().instructor;
          this.form.desc = qs.data().desc;
          this.previewImgDownloadUrl = this.previewImgUrl = qs.data().img;
          this.form.price = qs.data().price.toLocaleString("tr-TR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          this.form.startDate = qs.data().startDate
            ? qs
                .data()
                .startDate.toDate()
                .toISOString()
                .substr(0, 10)
            : null;
          this.form.finishDate = qs.data().finishDate
            ? qs
                .data()
                .finishDate.toDate()
                .toISOString()
                .substr(0, 10)
            : null;

          if (qs.data().usagePeriod) {
            this.form.usagePeriod = qs.data().usagePeriod;
          }
        }
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: `Eğitim bilgilerini alınamadı.`
        });

        throw new Error(`Eğitim bilgileri alınamadı. ${err.message}`);
      }
    },

    convertToNumber(trCurrency) {
      return Number(trCurrency.replace(".", "").replace(",", "."));
    }
  },

  async mounted() {
    await this.getCourse();
  }
});
</script>

<style lang="scss" scoped></style>
